import axios from "axios";
const baseUrl = "/api";


//request interceptor to add the auth token header to requests
axios.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      config.headers["Authorization"] = "Bearer " + accessToken;
      config.headers["CUUID"] = sessionStorage.getItem("CUUID");
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

//response interceptor to refresh token on receiving token expired error
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    const originalRequest = error.config;


    let refreshToken = localStorage.getItem("refreshToken");
    if (refreshToken && error.response.status === 401 && !originalRequest._retry && (originalRequest.url != "/api/token/refresh/")) {
      originalRequest._retry = true;

      return axios
        .post(`${baseUrl}/token/refresh/`, { refresh: refreshToken })
        .then((res) => {
          if (res.status === 200) {
            localStorage.setItem("accessToken", res.data.access);
            localStorage.setItem("refreshToken", res.data.refresh);
            if (originalRequest.url == "/api/token/verify/") {
              originalRequest.data = { 'token': res.data.access }
            }
            return axios(originalRequest);
          }
          else {
            localStorage.removeItem("refreshToken");
            localStorage.removeItem("accessToken");
            window.location.reload();
            return Promise.reject(error);
          }

        });
    }
    return Promise.resolve(error);
  }
);

//functions to make api calls
const api = {
  post: (url, body) => {
    return axios.post(`${baseUrl}${url}`, body);
  },

  get: (url, params = null) => {
    return axios.get(`${baseUrl}${url}`, { params: params });
  },

  put: (url, body) => {
    return axios.put(`${baseUrl}${url}`, body);
  },

  delete: (url, params) => {
    return axios.delete(`${baseUrl}${url}`, { params: params })
  },

  patch: (url, body) => {
    return axios.patch(`${baseUrl}${url}`, body);
  },

  login: (body) => {
    return axios.post(`${baseUrl}/token/`, body);
  },
  refreshToken: (body) => {
    return axios.post(`${baseUrl}/token/refresh/`, body);
  },
  verifyToken: (body) => {
    return axios.post(`${baseUrl}/token/verify/`, body);
  },
  logout: (body) => {
    return axios.delete(`${baseUrl}/token/logout/`, body);
  },

  download: async (url, params = null, now = true) => {
    let response;
    if (isMediaUrl(url)) {
      response = await axios.get(url, { params: params, responseType: "blob" })
      // create file link in browser's memory
      const href = URL.createObjectURL(response.data);
      // create "a" HTML element with href to file & click
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', url.split('/')[url.split('/').length - 1]); //or any other extension
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    }
    else {
      response = await axios.get(`${baseUrl}${url}`, { params: params, responseType: "blob" })
      // create file link in browser's memory
      if (now) {

        const href = URL.createObjectURL(response.data);
        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', response.headers['content-disposition'].split("filename=")[1].replaceAll("\"", "")); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      }
      else {
        return response;
      }
    }

  },

  postDownload: async (url, body) => {
    try {
      const response = await axios.post(`${baseUrl}${url}`, body, { responseType: "blob" })
      // create file link in browser's memory
      const href = URL.createObjectURL(response.data);
      // create "a" HTML element with href to file & click
      const link = document.createElement('a');
      link.href = href;
      if (response.headers['content-disposition'].split("filename=").length > 1) {
        link.setAttribute('download', response.headers['content-disposition'].split("filename=")[1].replaceAll("\"", "")); //or any other extension
      }
      else if (response.headers['content-disposition'].split("filename*=").length > 1) {
        let filename = response.headers['content-disposition'].split("filename*=")[1].replaceAll("\"", "");
        filename = filename.split("''")[1];
        filename = decodeURIComponent(filename);
        link.setAttribute('download', filename); //or any other extension
      }
      else {
        link.setAttribute('download', "file"); //or any other extension
      }

      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    }
    catch (err) {
      return "error"
    }
  },


};

function isMediaUrl(url) {
  return url.indexOf("media") !== -1;
}

function isFullUrl(url) {
  return url.indexOf("http") === 0;
}
export default api;
import * as React from 'react';
import { ThemeProvider, createTheme, styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Button, Avatar } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ArrowDownward, ExpandMore } from '@mui/icons-material';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect } from 'react';
import { useState } from 'react';
import { Badge, ClickAwayListener, Menu, MenuItem, SwipeableDrawer, useMediaQuery } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import LogoutIcon from '@mui/icons-material/Logout';
import { Fade } from '@mui/material';
import { debounce } from '@mui/material';
import { ChevronRight } from '@mui/icons-material';
import { generateColorFromScopeName, getScopeInitials } from '../utils';
import { useGetNotificationsQuery } from '../rtk/notification';
import NotificationList from '../Notification/Notification';

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer - 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

// smaller with round border and #002c6a background when selected
const AppDrawerListItemButton = styled(ListItemButton, {
  shouldForwardProp: (prop) => prop !== 'navOpen'
})(({ theme, navOpen }) => ({
  borderRadius: "10px",
  backgroundColor: "#ffffff00",
  color: "black",
  marginRight: theme.spacing(1),
  marginLeft: theme.spacing(1),
  marginBottom: theme.spacing(1),
  "&:hover": {
    backgroundColor: "#002c6a22",
    color: "white",
  },
  "&.selected-nav": {
    backgroundColor: "#3087ff22",
    color: "white",
    "& .MuiListItemText-root": {
      opacity: navOpen ? 1 : 0,
      color: "#002c6aFF",
    },
    "& .MuiListItemIcon-root": {
      minWidth: 0,
      marginRight: navOpen ? theme.spacing(2) : 'auto',
      color: "#002c6aFF",
    },
  },
  "& .MuiListItemIcon-root": {
    minWidth: 0,
    marginRight: navOpen ? theme.spacing(2) : 'auto',
    color: "black",
  },
  "& .MuiListItemText-root": {
    opacity: navOpen ? 1 : 0,
    color: "black",
  },

}));


const LinkNavItem = ({ link, activeUrl, navOpen }) => {
  return <AppDrawerListItemButton
    navOpen={navOpen}
    component={Link} to={link.path}
    className={activeUrl.startsWith(link.path) ? "selected-nav" : ""}
    sx={{
      minHeight: 48,
      justifyContent: navOpen ? 'initial' : 'center',
      px: 2.5,
    }}
  >
    <ListItemIcon
    >
      {link.icon}
    </ListItemIcon>
    <ListItemText primary={link.text} sx={{ opacity: navOpen ? 1 : 0 }} />
  </AppDrawerListItemButton>;
}


const MenuNavItem = ({ link, activeUrl, navOpen }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  return <><AppDrawerListItemButton
    onClick={handleClick}
    className={activeUrl.startsWith(link.path) ? "selected-nav" : ""}
    navOpen={navOpen}
    key={link.text}

    sx={{
      minHeight: 48,
      justifyContent: navOpen ? 'initial' : 'center',
      px: 2.5,
    }}
  >
    <ListItemIcon
      sx={{
        minWidth: 0,
        mr: navOpen ? 3 : 'auto',
        justifyContent: 'center',
      }}
    >
      {link.icon}
    </ListItemIcon>
    <ListItemText primary={link.text} sx={{ opacity: navOpen ? 1 : 0 }} />

  </AppDrawerListItemButton>
    <Menu
      anchorEl={anchorEl}
      id="account-menu"
      open={open}
      onClose={handleClose}
      onClick={handleClose}


      PaperProps={{
        elevation: 0,
        sx: {
          overflow: 'visible',
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          mt: 1.5,

          '&:before': {
            position: 'absolute',
            top: 30,
            left: -5,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: 'left', vertical: 'center' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'center' }}
    >
      {link.elements.map(el => (
        <MenuItem
          key={el.text}
          component={Link} to={el.path}
          onClick={handleClose}>
          <ListItemIcon>
            {el.icon}
          </ListItemIcon>
          {el.text}
        </MenuItem>
      ))}

    </Menu>
  </>
}

export const MobileDrawer = (props) => {
  const [open, setOpen] = useState(false);
  const location = useLocation()
  const [activeUrl, setActiveUrl] = useState(location.pathname)

  const toggleDrawerOpen = () => {
    setOpen(!!!open);
  };

  useEffect(() => {
    setActiveUrl(location.pathname)
  }, [location])


  return (
    <>
      <IconButton onClick={toggleDrawerOpen} sx={{ marginLeft: "4px" }}>
        {open ? <CloseIcon /> : <MenuIcon />}
      </IconButton>
      <Box sx={{ display: 'flex', height: "100%" }}>
        <SwipeableDrawer anchor='left' variant="persistent" open={open} onClose={() => setOpen(false)} >
          <DrawerHeader sx={{ justifyContent: "flex-start" }}>
            <IconButton onClick={toggleDrawerOpen} sx={{ marginLeft: "4px" }}>
              {open ? <CloseIcon /> : <MenuIcon />}
            </IconButton>
          </DrawerHeader>
          {open ?
            <Box height={"6em"} sx={{ verticalAlign: "middle", display: "inline-flex" }} >
              <Fade in={open} timeout={1000}>
                <Typography margin={"auto"} textAlign={"center"}>{`${props.user?.first_name || ""} ${props.user?.last_name || ""}`}</Typography>
              </Fade>
            </Box>
            :
            <Typography height={"6em"}></Typography>
          }
          <IconButton onClick={props.openNotifications} sx={{ margin: "auto" }}>
            <Badge badgeContent={props.notificationsCount} color="primary">
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <Divider />

          <List>
            {props.links.map((link, index) => (
              <ListItem key={link.text} disablePadding sx={{ display: 'block' }}>
                {link.type === "link" ? <LinkNavItem link={link} activeUrl={activeUrl} navOpen={open} /> : <MenuNavItem link={link} activeUrl={activeUrl} navOpen={open} />}
              </ListItem>
            ))}
          </List>
          <List sx={{ marginTop: "auto" }}>
            <ListItem disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                onClick={props.logoff}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <LogoutIcon color='error' />
                </ListItemIcon>
                <ListItemText primary={"Déconnexion"} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          </List>
        </SwipeableDrawer>
        <Box component="main" sx={{ flexGrow: 1, height: "100%" }}>
          {props.children}
        </Box>
      </Box>
    </>
  );

}


const AppDrawer = (props) => {
  const [open, setOpen] = useState(false);
  const [notificationsMenuAnchor, setNotificationsMenuAnchor] = React.useState(null);
  const location = useLocation()
  const [activeUrl, setActiveUrl] = useState(location.pathname)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const { data: notifications, error, isLoading } = useGetNotificationsQuery({}, { pollingInterval: 5000 })

  const refBar = React.useRef(null);

  const toggleDrawerOpen = () => {
    setOpen(!!!open);
  };

  useEffect(() => {
    setActiveUrl(location.pathname)
  }, [location])

  const notificationCount = React.useMemo(() => {
    if (notifications) {
      return notifications.filter(n => !n.is_read).length
    }
    return 0
  }, [notifications])

  const renderDesktop = (ref) => {
    return (
      <ClickAwayListener onClickAway={() => setOpen(false)}>
        <Drawer variant="permanent" open={open} ref={ref} >
          <DrawerHeader sx={{ justifyContent: "flex-start" }}>
            <IconButton onClick={toggleDrawerOpen} sx={{ marginLeft: "4px" }}>
              {open ? <CloseIcon /> : <MenuIcon />}
            </IconButton>
          </DrawerHeader>
          <IconButton onClick={(e) => setNotificationsMenuAnchor(e.target)} sx={{ width: "fit-content", margin: "0 auto" }} >
            {
              notificationCount > 0 ?
                <Badge badgeContent={notificationCount} color="primary">
                  <NotificationsIcon />
                </Badge>
                :
                <NotificationsIcon />
            }
          </IconButton>
          <Menu
            id="notifications-menu"
            anchorEl={notificationsMenuAnchor}
            open={Boolean(notificationsMenuAnchor)}
            onClose={() => setNotificationsMenuAnchor(null)}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            sx={{ p: 2 }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", p: 2 }}>
              <NotificationList />
            </Box>
          </Menu>
          <Box height={"7em"} sx={{ verticalAlign: "middle", display: "flex", flexDirection: "column" }} >

            {open ?

              <Fade in={open} timeout={1000}>

                <Typography margin={"2em auto"} marginBottom={"0"} padding={0} textAlign={"center"}>{`${props.user?.first_name || ""} ${props.user?.last_name || ""}`}</Typography>
              </Fade>
              :
              <Typography height={"6em"}></Typography>
            }

          </Box>
          <Menu
            id="scope-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
          >
            {props.user?.possible_scopes?.map(scope => (
              <MenuItem key={scope.id} onClick={() => props.changeScope(scope.id)}>
                <Avatar sx={{ mr: 1, bgcolor: generateColorFromScopeName(scope.name) }}>
                  {getScopeInitials(scope.name)}
                </Avatar>
                {scope.name}
              </MenuItem>
            ))}
          </Menu>

          <Divider />

          <List>
            {props.links.map((link, index) => (
              <ListItem key={link.text} disablePadding sx={{ display: 'block' }}>
                {link.type === "link" ? <LinkNavItem link={link} activeUrl={activeUrl} navOpen={open} /> : <MenuNavItem link={link} activeUrl={activeUrl} navOpen={open} />}
              </ListItem>
            ))}
          </List>
          <List sx={{ marginTop: "auto" }}>
            <ListItem disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                onClick={props.logoff}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <LogoutIcon color='error' />
                </ListItemIcon>
                <ListItemText primary={"Déconnexion"} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          </List>
        </Drawer>
      </ClickAwayListener>
    )
  }

  const renderMobile = (ref) => {
    return (
      <>
        <IconButton onClick={toggleDrawerOpen} sx={{ marginLeft: "4px", position: "absolute", top: 0, left: 0, p: "4px" }}>
          {open ? <CloseIcon /> : <MenuIcon />}
        </IconButton>
        <Box sx={{ display: 'flex', height: "100%" }} ref={ref}>
          {/* <AppBar position="fixed" open={open} sx={{backgroundColor:"var(--color-bg-high)", backgroundImage:"linear-gradient(#ffffff00,#ffffff00)"}}>
            <Toolbar variant='dense'>
            </Toolbar>
          </AppBar> */}

          <SwipeableDrawer anchor='left' variant="persistent" open={open} onClose={() => setOpen(false)} >
            <DrawerHeader sx={{ justifyContent: "flex-start" }}>
              <IconButton onClick={toggleDrawerOpen} sx={{ marginLeft: "4px" }}>
                {open ? <CloseIcon /> : <MenuIcon />}
              </IconButton>
            </DrawerHeader>
            <Box height={"7em"} sx={{ verticalAlign: "middle", display: "flex", flexDirection: "column" }} >

              {open ?

                <Fade in={open} timeout={1000}>

                  <Typography margin={"2em auto"} marginBottom={"0"} padding={0} textAlign={"center"}>{`${props.user?.first_name || ""} ${props.user?.last_name || ""}`}</Typography>
                </Fade>
                :
                <Typography height={"6em"}></Typography>
              }
              {
                open && props.user?.possible_scopes?.length > 1 ?
                  <Fade in={open} timeout={1000}>
                    <Button variant="outlined"
                      onClick={(e) => setAnchorEl(e.currentTarget)}
                      sx={{ margin: "0 auto", borderColor: "white" }}
                    >
                      <Avatar sx={{ mr: 1, bgcolor: generateColorFromScopeName(props.user?.scope_name) }}>
                        {getScopeInitials(props.user?.scope_name)}
                      </Avatar>
                      {props.user?.scope_name}
                      <ExpandMore />

                    </Button>

                  </Fade>
                  :
                  <></>
              }
            </Box>
            <Menu
              id="scope-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
            >
              {props.user?.possible_scopes?.map(scope => (
                <MenuItem key={scope.id} onClick={() => props.changeScope(scope.id)}>
                  <Avatar sx={{ mr: 1, bgcolor: generateColorFromScopeName(scope.name) }}>
                    {getScopeInitials(scope.name)}
                  </Avatar>
                  {scope.name}
                </MenuItem>
              ))}
            </Menu>
            <Divider />

            <List>
              {props.links.map((link, index) => (
                <ListItem key={link.text} disablePadding sx={{ display: 'block' }}>
                  {link.type === "link" ? <LinkNavItem link={link} activeUrl={activeUrl} navOpen={open} /> : <MenuNavItem link={link} activeUrl={activeUrl} navOpen={open} />}
                </ListItem>
              ))}
            </List>
            <List sx={{ marginTop: "auto" }}>
              <ListItem disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                  onClick={props.logoff}
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                    }}
                  >
                    <LogoutIcon color='error' />
                  </ListItemIcon>
                  <ListItemText primary={"Déconnexion"} sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>
            </List>
          </SwipeableDrawer>
        </Box>
      </>
    )
  }


  return (

    <Box sx={{ display: 'flex', height: "100%" }}>
      {
        isMobile ? renderMobile(refBar) : renderDesktop(refBar)
      }
      <Box component="main" sx={{ flexGrow: 1, height: "100%", maxWidth: "100%" , width: `calc(100vw - ${open ? drawerWidth : 0}px)`}}>
        {props.children}
      </Box>


    </Box>
  );

}

export default AppDrawer;
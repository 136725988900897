import React, { useState, useEffect, useRef } from "react";
import { Button, TextField, Typography, Stack, Paper, Grid, Container, Box, Divider, FormControlLabel, Switch, Skeleton, Snackbar, Tooltip, IconButton, List, ListItem, ListItemButton, Avatar, Menu, MenuItem, MenuList } from '@mui/material';
import { generateColorFromScopeName, getScopeInitials } from "./utils";
import { ExpandMore } from "@mui/icons-material";

const NoAccessPage = (props) => {

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);


    return (
        <Container sx={{ height: "80%", justifyContent: "center", alignItems: "center", display: "flex" }}>
            <Paper sx={{ p: 2, m: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography variant='h4'>Vous n'avez pas les droits pour accéder à cette application</Typography>
                

                <Divider sx={{ my: 2 }} />

                <Button onClick={props.logoff}>Se déconnecter</Button>
            </Paper>
        </Container>
    )
}
export default NoAccessPage
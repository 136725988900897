import React, { useState, useEffect, useRef } from "react";
import { Login } from "./Login";
import { BrowserRouter, Navigate, Route, Router, Routes, useParams } from "react-router-dom";
import RegisterView from "./RegisterView";
import VerifyView from "./VerifyView";
import VerifyMFA from "./VerifyMFA";

const AuthenticationApp = (props) => {
    return(
    <BrowserRouter >
        <Routes>
            {/* <Route path="/lexicon/:serieId?" element={<Lexicon />} /> */}
            <Route path="/login" element={<Login verifyAuth={props.verifyAuth} />} />
            <Route path="/register/:user_id?/:confirmation_token?/" element={<VerifyView />} />
            <Route path="/verify-mfa/:loginToken" element={<VerifyMFA verifyAuth={props.verifyAuth} />} />
            {/* <Route path="/register/verify/:user_id?/:confirmation_token?/" element={<VerifyView />} />
            <Route path="/register" element={<RegisterView />} /> */}
            <Route path="*" element={<Login verifyAuth={props.verifyAuth} />} />
        </Routes>   
    </BrowserRouter>

    )
}
export default AuthenticationApp
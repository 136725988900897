import { rtkapi } from "./rtkapi"

const register = rtkapi.injectEndpoints({
    endpoints: (build) => ({
        register: build.mutation({
            query: (data) => ({url: '/register/', method:'post', data:data}),
            invalidatesTags: ['Team', 'Employee']
        }),
        
        verify: build.mutation({
            query: (data) => ({url: '/register/verify/', method:'post', data:data}),
        }),

        changePassword: build.mutation({
            query: ({id,...data}) => ({url: `/register/change-password/${id}/`, method:'patch', data:data}),
        }),

        getSelf: build.query({
            query: (params) => ({url: '/register/self/', method:'get', params:params}),
        }),

        getValidateUsername: build.query({
            query: (params) => ({url: '/register/validate-username/', method:'get', params:params}),
        }),

    })
})

export const { useRegisterMutation, useVerifyMutation, useChangePasswordMutation, useGetSelfQuery, useGetValidateUsernameQuery } = register

import * as React from 'react';
import { createTheme, responsiveFontSizes, ThemeProvider } from '@mui/material/styles';
import { yellow } from '@mui/material/colors';
import Button from '@mui/material/Button';

let theme = createTheme({
  typography: {
    fontSize:13,
    fontFamily: [
      'Roboto',
      'sans-serif',
    ].join(','),
  },
  palette: {
    primary: {
      main: "#002c6aFF",
      contrastText: "#FFF",
      light: "#3087ff22",
    },
    secondary: {
      main: '#90a8e0',
    },
  },
  components: {
    MuiTextField: {
        styleOverrides: {
            root: {
                // backgroundColor: "#bad7ff",
            }
        }
    },
    MuiListSubheader: {
      styleOverrides: {
        // Name of the slot
        root: {
          backgroundColor:"#353535",
          color:"var(--font-color)"
        }
      }
    },
        // Name of the component
    MuiListItem: {
        styleOverrides: {
            // Name of the slot
            root: {
            // Some CSS
            cursor: 'pointer',
            
            },
        },
    },
    

    // MuiDrawer: {
    //   styleOverrides: {
    //     paper: {
    //       backgroundColor: "#000",
    //       color:"#FFF"
    //     },
    //     root: {
    //       color:"white"
    //     }
        
    //   }
    // }

    },
});

export default theme
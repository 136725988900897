import React, { useState, useEffect, useRef } from "react";
import { Button, TextField, Typography, Stack, Paper, Grid, Container, Box, Divider, FormControlLabel, Switch, Skeleton, Snackbar, Tooltip, IconButton, List, ListItem, ListItemButton, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import api from "../api";


const VerifyMFA = (props) => {
    const { mfaMethods, username } = useLocation().state
    const { loginToken } = useParams()
    const { t, i18n } = useTranslation();
    const [code, setCode] = useState("")
    const [selectedMethod, setSelectedMethod] = useState("")
    const [error, setError] = useState("")
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)
    const navigate = useNavigate()
    const params = useParams()



    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)

        try {
            let res = await api.post('/verify-mfa-code/', { token: loginToken, code: code, method: selectedMethod, username: username, mfa_type: selectedMethod })
            if (res.status === 200) {
                let accessToken = res.data.access
                let refreshToken = res.data.refresh
                localStorage.setItem("accessToken", accessToken);
                localStorage.setItem("refreshToken", refreshToken);
                navigate("/")
                await props.verifyAuth()
            }
            else {
                setError("Invalid code")
            }
        }
        catch (error) {
            setError("Invalid code")
        }
        setLoading(false)
    }

    const handleSelectMethod = (method) => {
        if (method === "email") {
            api.post('/send-mfa-code/', { token: loginToken, method: "email", username: username })
        }
        setSelectedMethod(method)
    }


    if (!loginToken) {
        navigate("/login")
    }

    if (!selectedMethod) {
        // choose method
        return (
            <Container>
                <Paper elevation={3} style={{ padding: 20, marginBottom: 20 }}>
                    <Typography variant="h4" component="h1" gutterBottom>
                        {t('Choose your MFA method')}
                    </Typography>
                    <List>
                        {mfaMethods.map((method, index) => (
                            <ListItem key={index}>
                                <ListItemButton onClick={() => handleSelectMethod(method)}>
                                    {t(method)}
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </Paper>
            </Container>
        )
    }

    return (
        <Container>
            <Paper elevation={3} style={{ padding: 20, marginBottom: 20 }}>
                <Typography variant="h4" component="h1" gutterBottom>
                    {t('Verify your MFA')}
                </Typography>
                <form onSubmit={handleSubmit}>
                    <Stack spacing={2}>
                        {
                            selectedMethod === "email" &&
                            <Typography variant="body1" gutterBottom>
                                {t('A code has been sent to your email')}
                            </Typography>
                        }
                        {
                            selectedMethod === "totp" &&
                            <Typography variant="body1" gutterBottom>
                                {t('Open your authenticator app and enter the code')}
                            </Typography>
                        }
                        <TextField
                            label={t('Code')}
                            variant="outlined"
                            value={code}
                            autoFocus
                            onChange={(e) => setCode(e.target.value)}
                        />
                        <Stack direction="row-reverse" spacing={2}>
                            <Button type="submit" variant="contained" disabled={loading}>{
                                loading ? <CircularProgress size={24} /> : t('Verify')}
                            </Button>
                            {
                                selectedMethod === "email" &&
                                <Button variant="outlined" onClick={() => api.post('/send-mfa-code/', { token: loginToken, method: "email", username: username })} disabled={loading}>
                                    {t('Resend code')}
                                </Button>
                            }
                        </Stack>
                        {error && <Typography color={"error"} variant="body1" gutterBottom>
                            {error}
                        </Typography>}
                    </Stack>
                </form>
            </Paper>
        </Container>
    )
}
export default VerifyMFA
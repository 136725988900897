import { rtkapi } from './rtkapi';

const notificationapi = rtkapi.enhanceEndpoints({ addTagTypes: ['Notification'] }).injectEndpoints({
    endpoints: (build) => ({
        getNotifications: build.query({
            query: (params) => ({ url: '/notifications/', method: 'get', params }),
            providesTags: ['Notification']
        }),
        getNotification: build.query({
            query: (id) => ({ url: `/notifications/${id}/`, method: 'get' }),
            providesTags: ['Notification']
        }),
        createNotification: build.mutation({
            query: (data) => ({ url: '/notifications/', method: 'post', data: data }),
            invalidatesTags: ['Notification']
        }),
        updateNotification: build.mutation({
            query: ({ id, ...data }) => ({ url: `/notifications/${id}/`, method: 'patch', data: data }),
            invalidatesTags: ['Notification']
        }),
        deleteNotification: build.mutation({
            query: (id) => ({ url: `/notifications/${id}/`, method: 'delete' }),
            invalidatesTags: ['Notification']
        }),
        markAsRead: build.mutation({
            query: (id) => ({ url: `/notifications/mark-as-read/${id}/`, method: 'post' }),
            invalidatesTags: ['Notification']
        }),
        markAllAsRead: build.mutation({
            query: () => ({ url: `/notifications/mark-all-as-read/`, method: 'post' }),
            invalidatesTags: ['Notification']
        }),
        deleteAllNotifications: build.mutation({
            query: () => ({ url: `/notifications/delete-all/`, method: 'delete' }),
            invalidatesTags: ['Notification']
        }),
    }),
})

export const {
    useGetNotificationsQuery,
    useGetNotificationQuery,
    useCreateNotificationMutation,
    useUpdateNotificationMutation,
    useDeleteNotificationMutation,
    useMarkAsReadMutation,
    useMarkAllAsReadMutation,
    useDeleteAllNotificationsMutation,
} = notificationapi; 
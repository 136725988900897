import React, { useState, useEffect, useRef } from "react";
import { Button, TextField, Typography, Stack, Paper, Grid, Container, Box, Divider, FormControlLabel, Switch, Skeleton, Snackbar, Tooltip, IconButton, List, ListItem, ListItemButton, Avatar, Menu, MenuItem, MenuList } from '@mui/material';
import { generateColorFromScopeName, getScopeInitials } from "./utils";
import { ExpandMore } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Page404 = (props) => {

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const { t, i18n } = useTranslation();
    


    return (
        <Container sx={{ height: "80%", justifyContent: "center", alignItems: "center", display: "flex" }}>
            <Paper sx={{ p: 2, m: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography variant='h4'>{t('Page not found')}</Typography>
                
                <Divider sx={{ my: 2 }} />

                <Button LinkComponent={Link} to="/">{t('Go to Home')}</Button>
            </Paper>
        </Container>
    )
}
export default Page404
export const pad = num => ("0" + num).slice(-2);

export const getTimeFromDate = dateString => {
    if (dateString) {
        const date = new Date(dateString);
        let hours = date.getHours(), minutes = date.getMinutes(), seconds = date.getSeconds();
        return pad(hours) + ":" + pad(minutes) + ":" + pad(seconds);
    } else {
        return "";
    }
};
export const stringifyTimeDifference = (time) => {
    let hours = Math.floor(time / 3600000);
    time = time - (hours * 3600000);
    let minutes = Math.floor(time / 60000);
    time = time - minutes * 60000;
    let secondes = Math.floor(time / 1000);

    return String(hours).padStart(2, "0") + ":" + String(minutes).padStart(2, "0") + ":" + String(secondes).padStart(2, "0");
};

export function searchIsValid(value, searchTerm) {
    if (!searchTerm) return true;
    if (!value) return false;
    return value.toLowerCase().replace(/\s/g, "").replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g,"").includes(searchTerm.toLowerCase().replace(/\s/g, "").replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g,""))
}

export function formatError(error) {
    if(!error) return;
    if (error.data) {
        return Object.keys(error.data).map(el => `${el}: ${error.data[el]}`).join("\n");
    } else if (error.request) {
        return error.request;
    } else {
        return error.message;
    }
}

export const generateColorFromScopeName = (scopeName) => {
    if (!scopeName) return "#000000";
    let hash = 0;
    for (let i = 0; i < scopeName.length; i++) {
      hash = scopeName.charCodeAt(i) + ((hash << 5) - hash);
    }
    let colour = '#';
    for (let i = 0; i < 3; i++) {
      let value = (hash >> (i * 8)) & 0xFF;

      colour += ('00' + value.toString(16)).substr(-2);
    }
    return colour;
  }

export const getScopeInitials = (scopeName) => {
    if (!scopeName) return "NA";
    return scopeName.split(" ").map((n) => n[0]).join("")
  }

export const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
  }
import React, { useState, useEffect, useRef } from "react";
import { Button, TextField, Typography, Stack, Paper, Grid, Container, Box, Divider, FormControlLabel, Switch, Skeleton, Snackbar, Tooltip, IconButton, List, ListItem, ListItemButton, Dialog, DialogActions, DialogContent, DialogTitle, Alert, Badge, Menu, MenuItem, ListItemIcon } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useLocation, Link } from 'react-router-dom';
import { useDeleteAllNotificationsMutation, useDeleteNotificationMutation, useGetNotificationsQuery, useMarkAllAsReadMutation, useMarkAsReadMutation } from "../rtk/notification";
import { ClearAll, Close, MarkAsUnread, MoreVert, OpenInNew } from "@mui/icons-material";
import api from "../api";

const Notification = (props) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate()
    const [deleteNotification, { error: deleteError }] = useDeleteNotificationMutation()
    const [markAsRead, { error: markAsReadError }] = useMarkAsReadMutation()
    const getLink = (notification) => {
        return "/" + notification.related_object_type + "/" + notification.related_object_id
    }

    const handleClick = (notification) => {
        markAsRead(notification.id)
        const link = getLink(notification)
        navigate(link)
    }

    const handleDelete = (notification) => {
        deleteNotification(notification.id)
    }

    const backgroundColor = props.notification.is_read ? "white" : "#f3f8fe"

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                bgcolor: backgroundColor,
                position: 'relative',
                p: 2,
                m: 1,
                borderRadius: 4,
                "&:hover": {
                    bgcolor: "#f3f8fe",
                    cursor: "pointer"
                },
                transition: "0.3s"
            }}
            onClick={() => handleClick(props.notification)}
        >
            <Box>
                <Typography variant="body1" component="p" gutterBottom>
                    {props.notification.message}
                </Typography>
                <Typography variant="caption" component="p" gutterBottom>
                    {props.notification.created_at}
                </Typography>
            </Box>
            <Box>
                <IconButton color="inherit" size="small"
                    onClick={
                        (e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            handleClick(props.notification)
                        }
                    }
                >
                    <OpenInNew fontSize="inherit" />
                </IconButton>
                <IconButton aria-label="close" color="inherit" size="small" onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleDelete(props.notification)
                }
                }>
                    <Close fontSize="inherit" />
                </IconButton>
            </Box>
            <Badge color="primary" variant="dot" invisible={props.notification.is_read} sx={{ position: 'absolute', top: 0, right: 0 }} />
        </Box>
    )
}


const NotificationList = (props) => {

    const { t, i18n } = useTranslation();
    const { data: notifications, error, isLoading } = useGetNotificationsQuery({}, { pollingInterval: 5000 })
    const [markAllAsRead, { error: markAllAsReadError }] = useMarkAllAsReadMutation()
    const [deleteAllNotifications, { error: deleteAllError }] = useDeleteAllNotificationsMutation()

    const [menuAnchor, setMenuAnchor] = useState(null)


    return (
        <Stack spacing={2}>
            <Stack direction="row" spacing={2} justifyContent="space-between" alignItems={"center"}>
                <Typography variant="h6" component="h2" gutterBottom>
                    {t('Notifications')}
                </Typography>
                <IconButton onClick={(e) => {
                    setMenuAnchor(e.currentTarget)
                }}>
                    <MoreVert />
                </IconButton>
                <Menu
                    id="simple-menu"
                    anchorEl={menuAnchor}
                    keepMounted
                    open={Boolean(menuAnchor)}
                    onClose={() => setMenuAnchor(null)}
                >
                    <MenuItem onClick={() => {
                        deleteAllNotifications()
                        setMenuAnchor(null)
                    }
                    }>
                        <ListItemIcon>
                            <ClearAll />
                        </ListItemIcon>
                        {t('Delete all')}
                    </MenuItem>
                    <MenuItem onClick={() => {
                        markAllAsRead()
                        setMenuAnchor(null)
                    }}>
                        <ListItemIcon>
                            <MarkAsUnread />
                        </ListItemIcon>
                        {t('Mark all as read')}</MenuItem>
                </Menu>
            </Stack>



            {
                !isLoading && notifications.length === 0 ?
                    <Alert severity="info">{t('No notifications')}</Alert>
                    :
                    <List>
                        {isLoading && <Skeleton variant="rectangular" width={210} height={118} />}
                        {error && <Alert severity="error">{t('Error')}</Alert>}
                        {notifications && notifications.map((notification) => (
                            <Notification notification={notification} />
                        ))}
                    </List>
            }
        </Stack>
    )
}


export default NotificationList;
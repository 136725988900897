import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
    lng: "fr",
    fallbackLng: "en",
    interpolation: {
        escapeValue: false,
    },
    resources: {
        fr: {
            translation: {
                "datetime_start": "Date de début",
                "datetime_end": "Date de fin",
                "post": "Post",
                "comment": "Commentaire",
                "comments": "Commentaires",
                "type": "Type",
                "user": "Utilisateur",
                "datetime": "Date",
                "content": "Contenu",
                "wind": "Vent",
                "temperature": "Température",
                "humidity": "Humidité",
                "pressure": "Pression",
                "clouds": "Nuages",
                "rain": "Pluie",
                "snow": "Neige",
                "weather": "Météo",
                "quantity": "Quantité",
                "coverage": "Pourcentage couvert",
                "Home": "Accueil",
                "Users": "Utilisateurs",
                "Email": "Email",
                "Username": "Nom d'utilisateur",
                "Role": "Rôle",
                "cancel": "Annuler",
                "upload": "Téléverser",
                "create": "créer",
                "Create Folder": "Créer un dossier",
                "Password": "Mot de passe",
                "Email Verified": "Email vérifié",
                'First Name': "Prénom",
                'Last Name': "Nom",
                "Settings": "Paramètres",
                "Language": "Langue",
                "Dark mode": "Mode sombre",
                "Submit": "Soumettre",
                "Admin": "Administrateur",
                "Choose your MFA method": "Choisissez votre méthode d'authentification à deux facteurs",
                "totp": "Application d'authentification",
                "MFA is now configured": "L'authentification à deux facteurs est maintenant configurée",
                "MFA is already configured": "L'authentification à deux facteurs est déjà configurée",
                "Configure TOTP": "Configurer TOTP",
                "Configure MFA": "Configurer l'authentification à deux facteurs",
                "Scan the QR code with your TOTP application": "Scannez le QR code avec votre application TOTP (Google Authenticator, Microsoft Authenticator, etc.)",
                "Or enter the secret key": "Ou entrez la clé secrète",
                "MFA us now configured": "L'authentification à deux facteurs est maintenant configurée",
                "folder": "dossier",
                "delete": "supprimer",
                "edit": "Modifier",
                "Are you sure you want to delete this file or folder?": "Êtes-vous sûr de vouloir supprimer ce fichier ou ce dossier?",
                "This action cannot be undone.": "Cette action ne peut pas être annulée.",
                "Repository": "Répertoire",
                "name": "Nom",
                "This password is too short. It must contain at least 8 characters.": "Ce mot de passe est trop court. Il doit contenir au moins 8 caractères.",
                "This password is too common.": "Ce mot de passe est trop commun.",
                "This password is entirely numeric.": "Ce mot de passe est entièrement numérique.",
                "This password is too similar to the email address.": "Ce mot de passe est trop similaire à l'adresse email.",
                "This password is too similar to the first name.": "Ce mot de passe est trop similaire au prénom.",
                "Passwords do not match.": "Les mots de passe ne correspondent pas.",
                "New password must be different from the old password.": "Le nouveau mot de passe doit être différent de l'ancien.",
                "Old password is invalid.": "L'ancien mot de passe est invalide.",
                "New password must be different from old password.": "Le nouveau mot de passe doit être différent de l'ancien.",
                "email": "Email",
                "by": "par",
                "Verify your MFA": "Vérifiez votre authentification à deux facteurs",
                "A code has been sent to your email": "Un code a été envoyé à votre email",
                "Open your authenticator app and enter the code": "Ouvrez votre application d'authentification et entrez le code",
                "Resend code": "Renvoyer le code",
                "Files": "Fichiers",
                "No notifications": "Aucune notification",
                'Page not found': "Page non trouvée",
                "Go to Home": "Aller à l'accueil",
                "Verify": "Vérifier",
                "Mark all as read": "Tout marquer comme lu",
                "Delete all": "Tout supprimer",
                "safe folder": "dossier sécurisé",
                "Folder name": "Nom du dossier",
                "You need to unlock the folder first": "Vous devez d'abord déverrouiller le dossier",
                "Folder must be unlocked first": "Le dossier doit d'abord être déverrouillé",
                "Add": "Ajouter",
                "a" : "un",
                "Why use secure folders?": "Pourquoi utiliser des dossiers sécurisés?",
                "Secure folders are encrypted and can only be accessed by you. Every file you upload to a secure folder is encrypted with a key derived from your password.": "Les dossiers sécurisés sont chiffrés et ne peuvent être accédés que par vous. Chaque fichier que vous téléversez dans un dossier sécurisé est chiffré avec une clé dérivée de votre mot de passe.",
                "If you forget your password, you will not be able to access your files. Even the administrator of the server cannot access your files.": "Si vous oubliez votre mot de passe, vous ne pourrez pas accéder à vos fichiers. Même l'administrateur du serveur ne peut pas accéder à vos fichiers.", 
                "New file": "Nouveau fichier",
                "New folder": "Nouveau dossier",
                "Favorite folders only": "Dossiers préférés uniquement",
                "Notification settings": "Paramètres de notification",
                "Save": "Enregistrer",
                "Email notifications": "Notifications par email",
                "In-app notifications": "Notifications dans l'application",
                "Recent folders": "Dossiers récents",
                "Favorite folders": "Dossiers préférés",
                "Confirm password": "Confirmez le mot de passe",
                "Email address": "Adresse email",
                "Email already exists": "L'adresse email existe déjà",
                "Password changed": "Mot de passe changé",
                "New password must be different than the old password.": "Le nouveau mot de passe doit être différent de l'ancien.",
                "Invalid code": "Code invalide",
                "Passwords do not match.": "Les mots de passe ne correspondent pas.",
                "Invalid password": "Mot de passe invalide",
                "New password": "Nouveau mot de passe",
                "Current password": "Mot de passe actuel",
                "Back to settings": "Retour aux paramètres",
                "Enter the code generated by your Authenticator application": "Entrez le code généré par votre application Authenticator",
                "Clear": "Effacer",
                "Are you sure you want to delete these files or folders?": "Êtes-vous sûr de vouloir supprimer ces fichiers ou dossiers?",
                "Delete": "Supprimer",
                "Are you sure you want to delete this user?": "Êtes-vous sûr de vouloir supprimer cet utilisateur?",
                "This action cannot be undone.": "Cette action ne peut pas être annulée.",
                "Upload files": "Téléverser des fichiers",
                "Do you want to upload these files?": "Voulez-vous téléverser ces fichiers?",
                "You can add recipients to the files": "Vous pouvez ajouter des destinataires aux fichiers",
                "Recipients": "Destinataires",
                "Upload": "téléverser",
                "resend email": "Renvoyer l'email",
                "You can resend the account creation email to this user if they did not receive it or if they lost their password.": "Vous pouvez renvoyer l'email de création de compte à cet utilisateur s'ils ne l'ont pas reçu ou s'ils ont perdu leur mot de passe.",
                "Move selection": "Déplacer la sélection",
                "Move": "Déplacer",
                "Files moved": "Fichiers déplacés",
                "Error moving files": "Erreur lors du déplacement des fichiers",
            }   
        },
        en: {
            translation: {
                "totp": "Authenticator App",
                "email": "Email",
                "by": "by",
                "resend email": "Resend Email",
            }
        }
    },
});

export default i18n;
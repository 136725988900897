import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Link, Stack, Typography } from "@mui/material";

const PageHeader = ({ title, needGoBack = false, subtitle, onClick, tooltip = null }) => {
    const navigate = useNavigate()


    return (
        <div className="page-title">
            {
                needGoBack ?
                    <Link onClick={() => navigate(-1)} className="back-arrow">
                        <FontAwesomeIcon size="xl" icon={faArrowLeft} />
                    </Link>
                    :
                    <a></a>


            }
            {
                onClick ?
                    <Button variant="h4" onClick={onClick} component={"a"} ><Typography variant="h4" >{title}</Typography></Button>
                    :
                    <Stack direction="row" spacing={2} justifyContent={"center"} alignItems={"center"}>
                        <Typography variant="h4" >{title}</Typography>
                        {tooltip}
                    </Stack>
            }
            <Typography variant="h6"> {subtitle}</Typography>
        </div>
    )

}
export default PageHeader
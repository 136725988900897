import React, { useEffect, useState, lazy, Suspense } from 'react';
import { Link, Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { BottomNavigation, BottomNavigationAction, CircularProgress, Container } from '@mui/material';

import { AddRoad, CalendarMonth, Category, DashboardCustomize, DashboardOutlined, Folder, PictureAsPdf, PunchClock, RoomService, Settings, SupervisorAccount, Task, TaskAlt, TrackChanges, Tune, Upload } from '@mui/icons-material';

import moment from 'moment';
import 'moment/locale/fr';
import AppDrawer, { MobileDrawer } from './components/AppDrawer';
import Page from './components/Page';
import { useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';
import Page404 from './Page404';


const LazyRepositoryExplorer = lazy(() => import('./RepositoryExplorer/RepositoryExplorer'));
const LazyFileViewer = lazy(() => import('./RepositoryExplorer/FileViewer'));
const LazyUserListView = lazy(() => import('./UsersManager/UserListView'));
const LazySettingsView = lazy(() => import('./Settings/SettingsView'));
const LazyConfigureTotp = lazy(() => import('./Settings/ConfigureTotp'));
const LazyDashboard = lazy(() => import('./Dashboard/Dashboard'));
const LazyPage404 = lazy(() => import('./Page404'));
const LazyDocumentSigningTempate = lazy(() => import('./RepositoryExplorer/DocumentSigning'));




moment.locale('fr')

const conditionnalBottomNavButton = (label, to, icon) => {
    return <BottomNavigationAction label={label} icon={icon} to={to} component={Link} />

}

const MainApp = (props) => {

    const { t, i18n } = useTranslation();
    // const bottomNavPath = []
    // const bottomNavItems = []

    const user = useSelector(state => state.user.user)

    const links = [
        { text: t('Home'), icon: <DashboardOutlined />, path: '/dashboard', type: 'link'},
        { text: t('Files'), icon: <Folder />, path: '/repository', type: 'link' },
    ]

    if (user.role === "admin") {
        links.push({ text: t("Users"), icon: <SupervisorAccount />, path: '/users', type: 'link' })
    }
    links.push({ text: t("Settings"), icon: <Settings />, path: '/settings', type: 'link' })


    const [bottomNavValue, setBottomNavValue] = useState(links.map(el => el.path).indexOf(window.location.pathname));

    useEffect(() => {
        setBottomNavValue(links.map(el => el.path).indexOf(window.location.pathname))
    }, [links])


    function bottomnav() {
        return <BottomNavigation
            showLabels
            style={{ position: "fixed", zIndex: "1", width: "100%", bottom: 0, margin: "0 -8px", color: "white", boxShadow: "0px -1px 5px 0px rgba(0,0,0,0.20)" }}
            value={bottomNavValue}
            onChange={(event, newValue) => {
                setBottomNavValue(newValue);
            }}
        >
            {links.map(
                el => conditionnalBottomNavButton(el.text, el.path, el.icon)
            )}
        </BottomNavigation>
    }

           


    return (
        <Router>
            <AppDrawer links={links} user={user} logoff={props.logoff} changeScope={props.changeScope}>
                <LocalizationProvider adapterLocale={'fr'} dateAdapter={AdapterMoment}>
                    <Suspense fallback={<Page>
                        <Container maxWidth="lg" style={{ height: "100vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <CircularProgress />
                        </Container>
                    </Page>}>
                        <Routes>
                            <Route path="/" element={<Navigate to="/dashboard" />}>
                            </Route>
                            <Route path="/repository" element={<LazyRepositoryExplorer/>} />
                            <Route path="/repository/:parent" element={<LazyRepositoryExplorer/>} />
                            <Route path="/file/:id" element={<LazyFileViewer/>} />
                            <Route path="/settings" element={<LazySettingsView/>} />
                            <Route path="/settings/totp" element={<LazyConfigureTotp/>} />
                            <Route path='/dashboard' element={<LazyDashboard/>} />
                            {
                                user.role === "admin" && <Route path="/users" element={<LazyUserListView />} />
                            }
                            {
                                user.role === "admin" && <Route path="/template" element={<LazyDocumentSigningTempate />} />
                            }

                            {/* 404 */}
                            <Route path="*" element={<Page404/>} />
                            {/* <Route path="/plan-files/" element={<PlanFilesList />} />
                            <Route path="/plan-files/:id" element={<PlanFileViewer />} /> */}
                        </Routes>
                    </Suspense>
                </LocalizationProvider>
            </AppDrawer>
        </Router>
    )

}

export default MainApp;
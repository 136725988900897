import React, { useState, useEffect, useRef } from "react";
import PageHeader from "./PageHeader";

import "./Page.css"
import { Container } from "@mui/material";

const Page = ({children, title, needGoBack, subtitle, style, onHeaderClick, maxWidth="xl", tooltip=null}) => {
    

    return (
        <Container style={style} maxWidth={maxWidth} className="full-height full-page" sx={{overflow:"auto"}}>
            {(title || subtitle || needGoBack) &&
            <PageHeader subtitle={subtitle} title={title} onClick={onHeaderClick} needGoBack={needGoBack} tooltip={tooltip} />}
            {children}
        </Container>
    )
}
export default Page
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import api from "../api.js";
import { Box, Grid, Link, Stack, TextField, Typography } from '@mui/material';
import logo from '../img/kre-sans-texte-white.png';
import './Login.css';
import { Container } from '@mui/material';
import Button from '@mui/material/Button'
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import PasswordTextField from '../components/PasswordTextField.jsx';
import { useTranslation } from 'react-i18next';

export const Login = (props) => {
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [errorLog, setErrorLog] = useState("");

  const [degrees, setDegrees] = useState({
    deg: 0,
    direction: 2,
    turn: 0
  });

  const [timer, setTimer] = useState(null);
  const { t, i18n } = useTranslation();

  useEffect(() => {
      setTimer(setInterval(() => {
        setDegrees(degInfo => {
          const deg = degInfo.deg;
          const rotationDirection = degInfo.direction;
          const turn = degInfo.turn;
          console.log("deg: ", deg);
          if (turn === 0 && deg > 370) {
              return { deg: deg - 5, direction: -5, turn: 1 };
          }
          else if (turn === 1 && deg < 10) {
              return { deg: deg + 5, direction: 3, turn: 2 };
          }
          else if (turn === 2 && deg > 200) {
            return { deg: deg, direction: 0, turn: 0 };
          }
          else if (turn === 3 && deg < 170) {
            return { deg: deg, direction: 0, turn: 0 };
          }
          else {
            return { deg: deg + rotationDirection, direction: rotationDirection, turn: turn };
          }
          // else {
          //   setIsRotating(false);
          //   return 0;
          // }
        });
      }, 10));
    return () => clearInterval(timer);
  }, []);


  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {

      var res = await api.post('/login/', { username: username, password: password })
      if (res.status === 200) {
        let loginToken = res.data.token;
        let mfaMethods = res.data.mfa_methods;
        if (mfaMethods.length > 0) {
          navigate("/verify-mfa/" + loginToken, { state: { mfaMethods: mfaMethods, username: username } })
        }

      }
      else {
        setErrorLog("Aucun compte actif avec cet identifiant et ce mot de passe")
      }


    } catch (error) {
      alert(error);
    }
  };

  return (
    <div className="login-wrapper">

      <Grid container borderRadius={"10px"}
        justifyContent="center"
        alignItems="stretch"
        display={"flex"}

      >
        <Grid item xs={12} sm={12} md={7} >
          <svg style={{ width: "100%", height: "auto" }} className="hero-image" viewBox="0 0 80 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g
              id="layer1">
              <path
                style={{ fill: "#002c6a", fillOpacity: 1, stroke: "#000000", strokeWidth: "0.171387px", strokeLinecap: "butt", strokeLinejoin: "miter", strokeOpacity: 1 }}
                d="M 5.7954332,4.3946406 V 14.666119 H 10.97356 V 12.908288 H 7.7197435 V 4.3817684 Z"
                id="path1" />
              <path
                id="path2"
                style={{ fill: "#002c6a", fillOpacity: 1, stroke: "#000000", strokeWidth: "0.171387px", strokeLinecap: "butt", strokeLinejoin: "miter", strokeOpacity: 1 }}
                d="m 14.594791,4.3822801 -2.754577,10.2805489 1.774124,0.01372 0.616925,-2.30166 h 2.894167 l 0.613578,2.28995 h 1.828019 L 16.811778,4.3822801 Z m 1.083556,2.5918929 1.013928,3.783909 h -2.027858 z" />
              <path
                style={{ fill: "#ffffff", fillOpacity: 1, stroke: "#000000", strokeWidth: "0.306666px", strokeLinecap: "butt", strokeLinejoin: "miter", strokeOpacity: 1 }}
                d="M 19.494634,16.634802 14.549208,34.910925 H 10.569019 L 5.6225628,16.630988 h 3.2815419 l 3.6997013,13.672479 3.705282,-13.693072 z"
                id="path2-5" />
              <g
                id="g12"
                transform="matrix(1.1627948,0,0,1.1627948,-13.387248,-117.42837)">
                <circle
                  style={{ fill: "#002c6a", fillOpacity: 1, stroke: "#000000", strokeWidth: "0.306666px", strokeLinecap: "butt", strokeLinejoin: "miter", strokeOpacity: 1, strokeMiterlimit: 1.7 }}

                  id="path3"
                  cx="36.668171"
                  cy="122.79243"
                  r="8.2182665" />
                <path
                  id="rect5"
                  style={{ fill: "#ffffff", fillOpacity: 1, stroke: "#000307", strokeWidth: "0.266", strokeLinecap: "butt", strokeLinejoin: "miter", strokeOpacity: 1 }}

                  d="m 40.864635,115.98059 -4.546061,6.42862 h -7.65975 c -0.11678,0 -0.21101,-0.014 -0.21101,0.21068 v 0.38398 c 0,0.21158 0.09423,0.21067 0.21101,0.21067 h 7.652993 l 4.539825,6.41981 c 0.06735,0.0953 0.113662,0.17812 0.293645,0.0503 l 0.314436,-0.22156 c 0.159674,-0.11527 0.117772,-0.19794 0.05042,-0.29319 l -4.493048,-6.35286 4.506562,-6.37258 c 0.06735,-0.0953 0.13976,-0.15911 -0.05093,-0.29319 l -0.313916,-0.22156 c -0.177824,-0.1212 -0.198401,-0.0847 -0.294176,0.0509 z"
                />
                <path
                  id="rect4"
                  style={{ fill: "#ffffff", fillOpacity: 1, stroke: "#000307", strokeWidth: "0.266", strokeLinecap: "butt", strokeLinejoin: "miter", strokeOpacity: 1, transform: `rotate(${degrees.deg}deg)`, transformOrigin: "36.6646px 122.7806px" }}
                  d="m 37.11575,117.61734 c -0.11567,-0.0101 -0.217195,0.075 -0.227346,0.19067 l -0.390112,4.44517 -2.867425,-3.41913 c -0.07461,-0.089 -0.206519,-0.10054 -0.295487,-0.0259 l -0.01005,0.008 c -0.08896,0.0746 -0.10054,0.20652 -0.02593,0.29548 l 2.867424,3.41915 -4.445174,-0.39012 c -0.11567,-0.0102 -0.217195,0.075 -0.227345,0.19066 l -0.0012,0.0134 c -0.01015,0.11566 0.07503,0.21668 0.190705,0.22683 l 4.44569,0.39015 -3.419699,2.8679 c -0.08896,0.0746 -0.100494,0.206 -0.02589,0.29497 l 0.0085,0.01 c 0.07461,0.089 0.206519,0.10054 0.295488,0.0259 l 3.419138,-2.86743 -0.390158,4.4457 c -0.01015,0.11566 0.07503,0.21668 0.190706,0.22683 l 0.01339,0.001 c 0.11567,0.0101 0.216636,-0.0745 0.226785,-0.19019 l 0.390158,-4.44569 2.867425,3.41913 c 0.07461,0.089 0.206519,0.10054 0.295487,0.0259 l 0.01005,-0.008 c 0.08896,-0.0746 0.100495,-0.206 0.02589,-0.29496 l -2.867895,-3.4197 4.446205,0.3902 c 0.115668,0.0101 0.216635,-0.0745 0.226784,-0.19019 l 0.0012,-0.0134 c 0.01015,-0.11566 -0.07448,-0.21714 -0.190146,-0.22729 l -4.44569,-0.39016 3.419139,-2.86743 c 0.08896,-0.0746 0.10054,-0.20652 0.02593,-0.29549 l -0.0085,-0.01 c -0.07461,-0.089 -0.206519,-0.10054 -0.295488,-0.0259 l -3.419138,2.86743 0.390112,-4.44518 c 0.01015,-0.11566 -0.07447,-0.21715 -0.190145,-0.2273 z" />
                <path
                  id="path3-7"
                  style={{ fill: "#ffffff", fillOpacity: 1, stroke: "#000307", strokeWidth: "0.266", strokeLinecap: "butt", strokeLinejoin: "miter", strokeOpacity: 1 }}
                  d="m 36.668105,119.9307 a 2.8616734,2.8616734 0 0 0 -2.861841,2.86184 2.8616734,2.8616734 0 0 0 2.861841,2.86132 2.8616734,2.8616734 0 0 0 2.861841,-2.86132 2.8616734,2.8616734 0 0 0 -2.861841,-2.86184 z m 0,0.69815 a 2.1633067,2.1633067 0 0 1 2.163175,2.16369 2.1633067,2.1633067 0 0 1 -2.163175,2.16318 2.1633067,2.1633067 0 0 1 -2.163176,-2.16318 2.1633067,2.1633067 0 0 1 2.163176,-2.16369 z" />
              </g>
              <path
                id="path5"
                style={{ fill: "#ffffff", fillOpacity: 1, stroke: "#000307", strokeWidth: "0.266", strokeLinecap: "butt", strokeLinejoin: "miter", strokeOpacity: 1 }}
                d="m 49.393878,16.755464 -0.08583,10.442398 c 0,0 0.213926,4.515202 -3.016629,4.540748 -3.230556,0.0257 -2.973498,-4.580318 -2.973498,-4.580318 l -0.08576,-10.436433 h -2.825867 v 12.785813 c 0,0 0.384816,5.422892 5.880317,5.392612 5.4955,-0.03023 5.904356,-5.39674 5.904356,-5.39674 V 16.721266 Z"
              />
              <path
                id="rect7"
                style={{ fill: "#ffffff", fillOpacity: 1, stroke: "#000307", strokeWidth: "0.266", strokeLinecap: "butt", strokeLinejoin: "miter", strokeOpacity: 1 }}
                d="m 68.388062,16.422486 c -0.118184,0 -0.215985,0.08151 -0.238644,0.190257 -0.0279,0.03872 -0.04808,0.08384 -0.04808,0.135047 v 17.885214 c 0,0.113489 0.08091,0.207745 0.189352,0.231943 0.04044,0.02919 0.08994,0.04698 0.144268,0.04698 h 8.485948 c 0.135068,0 0.244052,-0.106512 0.244052,-0.238396 V 31.7733 c 0,-0.131931 -0.108977,-0.237815 -0.244052,-0.237815 h -5.646278 v -4.266074 h 3.538166 c 0.135068,0 0.243452,-0.106512 0.243452,-0.238407 v -2.681208 c 0,-0.13193 -0.108384,-0.238407 -0.243452,-0.238407 H 71.274628 V 19.76371 h 5.871095 c 0.135068,0 0.243453,-0.106512 0.243453,-0.238419 v -2.864406 c 0,-0.131942 -0.108384,-0.238396 -0.243453,-0.238396 z" />
              <path
                id="rect11"
                style={{ fill: "#ffffff", fillOpacity: 1, stroke: "#000307", strokeWidth: "0.266", strokeLinecap: "butt", strokeLinejoin: "miter", strokeOpacity: 1 }}
                d="m 54.00794,16.485672 c -0.135017,0 -0.24336,0.108954 -0.24336,0.243966 v 2.98643 c 0,0.135012 0.108349,0.243361 0.24336,0.243361 h 4.521704 v 14.706796 c 0,0.135024 0.108349,0.243966 0.24336,0.243966 h 2.802556 c 0.135016,0 0.243961,-0.108953 0.243961,-0.243966 V 19.959429 h 4.521103 c 0.135017,0 0.243962,-0.108373 0.243962,-0.243361 v -2.98643 c 0,-0.135023 -0.108943,-0.243966 -0.243962,-0.243966 z" />
              <path
                id="rect12855-5-3"
                style={{ fill: "#002c6a", fillOpacity: 1, stroke: "#000307", strokeWidth: "0.266", strokeLinecap: "butt", strokeLinejoin: "miter", strokeOpacity: 1 }}

                d="m 37.636528,38.589844 -0.867975,0.868654 v 9.824198 h 1.474825 l 0.860346,-0.861015 v -9.831837 z" />
              <path
                id="rect12855-5-3-5"
                style={{ fill: "#002c6a", fillOpacity: 1, stroke: "#000307", strokeWidth: "0.266", strokeLinecap: "butt", strokeLinejoin: "miter", strokeOpacity: 1 }}
                d="m 42.366597,38.631228 -2.772188,2.70037 v 3.264105 l 6.123019,-5.964475 z" />
              <path
                id="rect12855-5-3-5-1"
                style={{ fill: "#002c6a", fillOpacity: 1, stroke: "#000307", strokeWidth: "0.266", strokeLinecap: "butt", strokeLinejoin: "miter", strokeOpacity: 1 }}
                d="m 39.566162,45.826881 2.64962,3.440408 3.240426,0.007 -5.852371,-7.598934 z" />
              <text
                style={{ fontStyle: "normal", fontWeight: "normal", fontSize: "9.2031px", lineHeight: "1.25", fontFamily: "sans-serif", fill: "#000000", fillOpacity: 1, stroke: "none", strokeWidth: "0.232559", strokeDasharray: "none" }}
                x="45.39286"
                y="49.262737"
                id="text18073"><tspan
                  id="tspan18071"
                  style={{
                    fontStyle: "normal",
                    fontVariant: "normal",
                    fontWeight: "normal",
                    fontStretch: "normal",
                    fontSize: "9.2031px",
                    fontFamily: "'Samyak Tamil'",
                    fill: "#000000",
                    fillOpacity: 1,
                    stroke: "none",
                    strokeWidth: "0.232559"
                  }}

                  // style="font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:9.2031px;font-family:'Samyak Tamil';-inkscape-font-specification:'Samyak Tamil, Normal';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-variant-east-asian:normal;stroke-width:0.232559;stroke-dasharray:none"
                  x="45.39286"
                  y="49.262737">reasoft</tspan></text>
              <text
                style={{ fontStyle: "normal", fontWeight: "normal", fontSize: "4.10208px", lineHeight: "1.25", fontFamily: "sans-serif", fill: "#000000", fillOpacity: 1, stroke: "none", strokeWidth: "0.151175", strokeDasharray: "none" }}
                x="28.008974"
                y="41.129383"
                id="text18073-1"><tspan
                  id="tspan18071-2"
                  style={{
                    fontStyle: "normal",
                    fontVariant: "normal",
                    fontWeight: "normal",
                    fontStretch: "normal",
                    fontSize: "4.10208px",
                    fontFamily: "'Samyak Tamil'",
                    fill: "#000000",
                    fillOpacity: 1,
                    stroke: "none",
                    strokeWidth: "0.151175"
                  }}
                  x="28.008974"
                  y="41.129383">par</tspan></text>
            </g>
          </svg>
        </Grid>
        <Grid backgroundColor={"#FFF"} className="login-right" item xs={12} sm={12} md={5}

        >
          <form onSubmit={handleSubmit} style={{ width: "100%", height: "100%", padding: "1em" }}>
            <Stack
              spacing={{ md: 6, sm: 3, xs: 3 }}
              justifyContent="space-between"
              height={"100%"}
              alignItems="center"


            >
              <Typography variant='h4'>Connexion</Typography>
              <Stack
                width={(isMobile ? "100%" : "80%")}
                spacing={1}
              >
                <TextField
                  label={t("Email address")}
                  autoFocus={true}
                  className={"form-field " + (errorLog && "invalid")}
                  type="email"
                  fullWidth
                  onChange={e => { setUserName(e.target.value); setErrorLog("") }}
                  variant="standard" />

                <PasswordTextField
                  label={t("Password")}
                  type="password"
                  name="password"
                  onChange={e => { setPassword(e.target.value); setErrorLog("") }}
                  className={"form-field " + (errorLog && "invalid")}
                  autoComplete="false"
                  fullWidth
                  variant="standard"
                />
                {
                  errorLog ?
                    <Box sx={{ width: '100%' }}>
                      <Typography variant="body1" textAlign={"center"} color="error">{errorLog}</Typography>
                    </Box>
                    :
                    <Box sx={{ width: '100%', visibility: "hidden", height: "3em" }}>

                    </Box>
                }
              </Stack>
              <Button variant="contained" type="submit">Connexion</Button>
              <div style={{ justifyContent: "center", alignItems: "center", display: "flex" }}>
                {/* <Link href='/register'>S'enregistrer</Link> */}
              </div>
            </Stack>

          </form>


        </Grid>
      </Grid>

    </div>
  )
}

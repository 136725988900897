import api from './api';
import './App.css';
import { Login } from './Login/Login.jsx';
import React, { useEffect, useState } from 'react';
import MainApp from './MainApp';
import { ThemeProvider } from '@emotion/react';
import theme from './styles/theme';


import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { Provider, useDispatch, useSelector } from 'react-redux';
import store from './rtk/store';
import { Button, CircularProgress, Container, Paper, Typography, responsiveFontSizes } from '@mui/material';
import AuthenticationApp from './Login/AuthenticationApp';
import { setUser } from './rtk/userSlice';
import { rtkapi } from './rtk/rtkapi.js';
import NoAccessPage from './NoAccessPage.jsx';

import { useTranslation } from 'react-i18next';

const App = (props) => {
  let [token, setToken] = useState(localStorage.getItem("accessToken"))
  let [isConnected, setIsConnected] = useState(null)
  let [isLoading, setIsLoading] = useState(true)

  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    verifyAuth()
  }, [])

  useEffect(() => {
    const navigatorlang = navigator.language.split('-')[0] || 'fr'
    if (user?.preffered_language) {
      localStorage.setItem('lang', user.preffered_language)
      i18n.changeLanguage(user.preffered_language)
    }
    else if (localStorage.getItem('lang') === null) {
      localStorage.setItem('lang', navigatorlang)
    }
    else {
      i18n.changeLanguage(localStorage.getItem('lang') )
    }
  }, [user])

  useEffect(() => {
    if (isConnected) {
      getUser()
    }
    else if (isConnected !== null) {
      setIsLoading(false)
    }
  }, [isConnected])

  const logoff = () => {
    dispatch(rtkapi.util.resetApiState())
    dispatch(setUser(null))
    localStorage.clear()
    store.dispatch(store => store = undefined)
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    setIsConnected(false)

    // remove cache in redux
  }




  const verifyAuth = async () => {
    let storedToken = localStorage.getItem("accessToken");

    if (storedToken) {
      let res = await api.post('/token/verify/', { 'token': storedToken })

      if (res.status === 200) {
        if (!token) {
          setToken(storedToken)
        }
        setIsConnected(true)
        // getIsAdmin()
        return
      }
    }
    setIsConnected(false)

  }

  const getUser = async () => {
    let res = await api.get('/me/')
    if (res.status === 200) {
      dispatch(setUser(res.data))
    }
    else {
      dispatch(setUser(null))
    }
    setIsLoading(false)
  }

  const changeScope = async (scopeId) => {
    await api.post('/me/change-scope/', { 'scope': scopeId })
    dispatch(rtkapi.util.resetApiState())
    getUser()
    window.location.replace("/")
  }

  let theme2 = responsiveFontSizes(theme)


  if (isLoading) {
    return (
      <ThemeProvider theme={theme2}>
        <Container sx={{ height:"80%", justifyContent:"center", alignItems:"center", display:"flex" }}>
            <CircularProgress size={100} />
          </Container>
      </ThemeProvider>
    )
  }            


  if (isConnected && user) {
    return (
      <ThemeProvider theme={theme2}>
        <MainApp logoff={logoff} changeScope={changeScope} />
      </ThemeProvider>
    )
  }

  if (isConnected && user && !user?.is_admin) {
    return (
      <ThemeProvider theme={theme2}>
        <NoAccessPage logoff={logoff} changeScope={changeScope} user={user} />
      </ThemeProvider>
    )
  }


  return (
    <ThemeProvider theme={theme}>
      <AuthenticationApp verifyAuth={verifyAuth} ></AuthenticationApp>
    </ThemeProvider>
  )
}

export default App;

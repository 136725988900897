import React, { useState, useEffect, useRef } from "react";
import { useGetSelfQuery, useGetValidateUsernameQuery, useVerifyMutation } from "../rtk/register";
import { Button, Container, Paper, Stack, TextField, Typography, Grid } from "@mui/material";
import { useParams, Link } from "react-router-dom";
import PasswordTextField from "../components/PasswordTextField";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const VerifyView = (props) => {
    const { user_id, confirmation_token } = useParams();
    const [submitModifications, responseVerification] = useVerifyMutation();
    // if (isUninitialized || isLoading) {
    //     return <></>
    // }
    const [user, setUser] = useState({ id: user_id, first_name: "", last_name: "", username: "", email: "", password: "", password2: "", is_active: false });
    const [usernameIsValid, setUsernameIsValid] = useState(false);
    const { data: remoteMe, isSuccess, error, isLoading, isUninitialized, response } = useGetSelfQuery({ confirmation_token: confirmation_token, user_id: user_id }, { skip: user_id === "", refetchOnMountOrArgChange: true });
    const { data: usernameVerification, refetch: refetchUsernameVerification } = useGetValidateUsernameQuery({ username: user.username, confirmation_token: confirmation_token, user_id: user_id });

    const navigate = useNavigate()

    const { t, i18n } = useTranslation();

    useEffect(() => {
        verifyUsernameAvailable()
    }, [user.username])


    const verifyUsernameAvailable = async () => {
        const usernameVerificationResult = await refetchUsernameVerification({ username: user.username, confirmation_token: confirmation_token, user_id: user_id })
        if (usernameVerificationResult.data) {
            setUsernameIsValid(usernameVerificationResult.data.available)
        }
    }

    // useEffect(() => {
    //     if (responseVerification.data) {
    //         navigate("/")
    //     }
    // }, [responseVerification.data])


    useEffect(() => {
        if (remoteMe) {
            setUser(remoteMe)
        }
    }, [remoteMe])


    const handleSubmit = (e) => {
        e.preventDefault();
        submitModifications({ confirmation_token:confirmation_token, user: user });
    }
    if (isLoading) {
        return <></>
    }

    if (error?.data === "Token is invalid or expired. Please request another confirmation email by signing in.") {
        return (
            <Container maxWidth="md" sx={{ height: "100%", p: 10 }}>
                <Paper elevation={6} sx={{ p: 4 }}>
                    <Typography variant="h4">Le lien de confirmation est invalide ou expiré</Typography>
                </Paper>
            </Container>
        )
    }

    if (user.is_active) {
        return (
            <Container maxWidth="md" sx={{ height: "100%", p: 10 }}>
                <Paper elevation={6} sx={{ p: 4 }}>
                    <Typography variant="h4">Votre compte a déjà été activé</Typography>
                    <Typography variant="body1">Vous pouvez vous connecter en cliquant sur le lien suivant: <Link href="/login">Connexion</Link></Typography>
                </Paper>
            </Container>
        )
    }

    return (
        <Container maxWidth="md" sx={{ height: "100%" }}>

            <Paper elevation={6} sx={{ p: 4 }} component={"form"} onSubmit={handleSubmit}>
                <Typography variant="h4">Création de votre compte</Typography>
                <Stack sx={{ my: 2 }}>
                    <TextField
                        name="first_name"
                        label="Prénom"
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        autoFocus
                        value={user.first_name}
                        onChange={(e) => setUser({ ...user, first_name: e.target.value })}
                    />
                    <TextField
                        name="last_name"
                        label="Nom"
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        value={user.last_name}
                        onChange={(e) => setUser({ ...user, last_name: e.target.value })}
                    />
                    <TextField
                        name="email"
                        label="Email"
                        variant="outlined"
                        margin="normal"
                        required
                        disabled
                        fullWidth
                        value={user.email}
                        onChange={(e) => setUser({ ...user, email: e.target.value })}
                    />
                    <TextField
                        name="username"
                        label="Nom d'utilisateur"
                        variant="outlined"
                        margin="normal"
                        disabled
                        required
                        fullWidth
                        value={user.email}
                        onChange={(e) => setUser({ ...user, username: e.target.value })}
                        error={!usernameIsValid}
                        helperText={!usernameIsValid ? "Ce nom d'utilisateur est déjà pris" : ""}
                    />
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                        <PasswordTextField
                            name="password"
                            label="Mot de passe"
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            type="password"
                            value={user.password}
                            onChange={(e) => setUser({ ...user, password: e.target.value })}
                        />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                        <PasswordTextField
                            name="password2"
                            label="Confirmer le mot de passe"
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            type="password"
                            value={user.password2}
                            onChange={(e) => setUser({ ...user, password2: e.target.value })}
                        />
                        </Grid>
                    </Grid>
                </Stack>
                <Stack direction="row" justifyContent="flex-end">
                    <Button variant="contained" color="primary" type="submit" sx={{ mt: 2 }}
                        disabled={responseVerification.data}>
                        Valider
                    </Button>

                    {/* <Link href="/login" variant="body2">
                        {"Vous avez déjà un compte ?"}
                    </Link> */}
                </Stack>
                {
                    responseVerification.error &&
                    <Typography variant="body1" sx={{ mt: 2, color: "red" }} >
                        {
                            
                            responseVerification?.error?.data?.password.map((el, i) => {
                                return (t(el) || "Une erreur est survenue")
                            })
                        }
                    </Typography>
                }
                {
                    responseVerification.data && <Stack direction="column" alignItems="center">
                        <Typography variant="body1" sx={{ mt: 2, color: "green" }} href="/repository" >
                            Votre compte a bien été créé, vous pouvez vous connecter
                        </Typography>
                        <Button variant="contained" color="primary" sx={{ mt: 2 }} component={Link} to="/">
                            Connexion
                        </Button>
                        
                    </Stack>
                }

            </Paper>
        </Container>
    )
}
export default VerifyView